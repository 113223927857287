const VERSION = 'jenkins-eng-mypargo-25.02.12-1';
const API_URL = 'https://api.mypargo.pargo.co.za';
const PAYMENT_API_URL = 'https://api.live.pargo.co.za';
const SENTRY_DSN = 'https://e6ce2e57798e4e269cfd86e5b71aefb8@o640290.ingest.sentry.io/6159254';
const GA_TRACKING_ID = 'G-3591Q4FBH2';
const X_FORWARDER = 'none';
const SHOPIFY_PLUGIN_URL = 'https://shopify.pargo.co/';
const ANALYTICS_API_URL = 'https://analytics.monitoring.pargo.co/api/v1/analytics/ingest';

export const environment = {
  production: true,
  staging: false,
  analyticsApiUrl: ANALYTICS_API_URL,
  apiUrl: API_URL,
  paymentApiUrl: PAYMENT_API_URL,
  X_FORWARDER,
  version: VERSION,
  pargoMail: 'support@pargo.co.za',
  peachUrl: 'https://eu-prod.oppwa.com/v1/paymentWidgets.js',
  gaTrackingID: GA_TRACKING_ID,
  returnsAppURL: 'https://returns.pargo.co.za/',
  sentryDSN: SENTRY_DSN,
  shopifyPluginUrl: SHOPIFY_PLUGIN_URL,
  trackAndTraceUrl: 'https://track.pargo.co.za/',
};
